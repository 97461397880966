<template>
  <div>
    <slideshow-module :slides="slides"></slideshow-module>
    <worlds-module></worlds-module>
  </div>
</template>

<script>
import WorldsModule from "@/components/modules/WorldsModule.vue";

export default {
  components: { WorldsModule },
  setup() {
    return {
      slides: [
        {
          img: require("@/assets/slideshow-items/worlds.jpg"),
          title: '<span style="font-size:40px">The Worlds</span>',
        },
      ],
    };
  },
};
</script>