<template>
  <text-module>
    <p
      style="display: block; margin: 0px 10px 20px; color: #888888"
      align="right"
    >
    <!-- UniverseTheGame/images/pic7903E970768A84D845BBBD966C46CF3B.png -->
      <img
        style="padding-top: 40px"
        height="175"
        alt=""
        hspace="0"
        src="@/assets/the-game/ag-island.png"
        width="269"
        align="right"
        border="0"
      />
    </p>
    <div style="display: block; margin: 0px 10px 20px; color: #888888">
      <h2 style="font-size: 24px">A world of worlds</h2>
      The Maelstrom has blown Planet Crux into thousands of worlds before
      forming into a swirling black hole of chaos energy! <br />
      <br />
      Opposite the dark menace’s center sits Nexus City. Nexus Tower rises from
      the city’s center, glowing with light from the last shard of Pure
      Imagination! <br />
      <br />
      <br />
      <!-- UniverseTheGame/images/picEAB9016653EF3D40CB511D1575985E83.png -->
      <img
        height="159"
        alt=""
        hspace="0"
        src="@/assets/the-game/crystal-search.png"
        width="269"
        align="left"
        border="0"
      />
    </div>
    <div style="display: block; margin: 0px 10px 20px; color: #888888">
      <h2 style="font-size: 24px">Exploration</h2>
      Other large planet chunks drift around Nexus City. As a LEGO Universe
      Minifigure, you can explore in the festive Avant Gardens, then hop in your
      custom LEGO spaceship and fly to new worlds of creative fun! <br />
      <br />
      You might set off on pirate-themed adventures in Gnarled Forest, join a
      Nexus Force faction in Nimbus Station, or explore ancient ninja ways in
      Forbidden Valley! <br />
      <br />
      <!-- UniverseTheGame/images/pic04ABC9DDDEFB605F8BFB53A5C3191B16.png -->
      <img
        style="padding-top: 40px"
        height="175"
        alt=""
        hspace="0"
        src="@/assets/the-game/duck-model.png"
        width="269"
        align="right"
        border="0"
      />
      <h2 style="font-size: 24px">Creating life</h2>
      Drifting among the larger worlds are thousands upon thousands of smaller
      ones. Now, you can settle on one of these worlds and use your creativity
      to help push back the Maelstrom! <br />
      <br />
      Get inspired flying between your friends’ properties, then head for your
      own LEGO Universe world. There, you can build anything you like— LEGO
      brick by brick— and bring your creations to life!<br />
    </div>
  </text-module>
</template>